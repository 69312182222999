<template>
  <div>



    <!-- {{ loop  }} -->
    <div class="text-center" v-if="isLoading">
      <h5>Ekran Yükleniyor..</h5>

      <b-spinner variant="primary" label="Text Centered" />
    </div>

    <!-- <div class="row" id="myLoopHolder">dd<vue-perfectlooper v-bind="loop"/></div> -->
    <b-card class="profile-header mb-2" img-top alt="cover photo" body-class="p-0" style="object-fit: fill;width:100%">
      <!-- currentindex {{ currentSlideIndex }} -->
      <!-- <h5 class="text-center" v-if="loop.src.length == 0 && !isLoading">Seçilen Tarihe ait Kayıt Yoktur!</h5> -->
   <video-player v-if="activeTab == 'logs'" :images="screensLog" @getDateScreen="getDateScreen" @postDateScreenToProfileHeader="SelectedDateScreenFromVideoPlayer($event)"></video-player>
     
      <!-- <div ref="fullscreenSlide" :class="{ 'fullscreen': !isFullscreen }">
        <div class="slider-container" v-if="loop.src.length > 0">
          <div class="slider ">
            <div class="slide" :class="{ 'fullscreen': !isFullscreen }">
              <img :src="getSliderData" alt="logo">

            </div>
          </div>

        </div>
        <div class="px-5">
          ap {{ autoplay }}
          index {{ currentSlideIndex }}
          <label v-if="loop.src.length > 0"
            :style="{ borderRadius: '5px', paddingLeft: '10px', width: '100%', background: 'white', color: loop.src[currentSlideIndex].isProductive == 0 ? 'red' : 'green' }">{{
      loop.src[currentSlideIndex].isProductive == 0 ? ' Verimsiz ' : ' Verimli ' }}: {{
      loop.src[currentSlideIndex].exeName }} - {{ loop.src[currentSlideIndex].name }}</label>
       

          lll{{ loop.src }}
          dd{{ loop.dates }}
          <VueSlider v-if="loop.src.length > 0" v-model="currentSlideIndex" :data="loop.src" :max="loop.src.length"
            :dataLabel="loop.dates"  :hide-label="true" />
        </div>

        <div class="row controls w-100">
          <b-col md="2" class="pt-1">
            lopp {{ loop.src.length }}
            <b-button v-if="loop.src.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
              class="btn-icon" :disabled="autoplay" @click="startAutoplay">
              <feather-icon icon="PlayIcon" size="20" />
            </b-button>
            <b-button v-if="loop.src.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
              class="btn-icon ml-1" :disabled="!autoplay" @click="stopAutoplay">
              <feather-icon icon="PauseCircleIcon" size="20" />
            </b-button>
        

          </b-col>

      
          <b-col md="2">
            <b-form-group v-if="!isFullscreen">

              <flat-pickr v-model="rangeDate" class="form-control mt-1" :placeholder="dateText"
                :config="{ dateFormat: 'Y-m-d H:i' }" />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <div class="mt-1 " v-if="!isFullscreen">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                Getir
              </b-button>
            </div>
          </b-col>
          <b-col md="2">
            <b-button v-if="loop.src.length > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary"
              @click="speed" @input="setAutoplayDelay" class="btn-icon mt-1 float-right">
              Hız {{ speedCount }}X
            </b-button>
          </b-col>

          <b-col md="3" v-if="loop.src.length > 0">
           
            Süre: <span style='color:green' class=" mr-5 text-left">{{ activeTime }}</span><br>
            Toplam Süre: <span style="color:red" class=" text-right">{{ formattedDuration }}</span>

          </b-col>
          <b-col md="2" v-if="loop.src.length > 0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="btn-icon ml-1"
              @click="toggleFullscreen">
              <feather-icon icon="Maximize2Icon" size="20" />
            </b-button>
          </b-col>

        </div>
      </div> -->

      <!-- profile picture -->
      <div class="position-relative" v-if="userData">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img src="@/assets/user_default.png" rounded fluid alt="profile photo" />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-3">
            <h2 class="text-white">
              {{ userData.fullName }}
            </h2>
            <p class="text-softwhite">
              Çalışma Durumu: {{ userData.isActive ? 'Aktif Çalışan' : 'Çalışan değil' }}
            </p>
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar toggleable="md" type="light">
          <!-- toggle button -->
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>
          <!--/ toggle button -->

          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs v-model="tabIndex" pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <b-nav-item role="presentation" :active="isActive('profile')" class="font-weight-bold"
                  @click="setActive('profile')">
                  <span class="d-none d-md-block">Profil</span>
                  <feather-icon icon="UserIcon" class="d-block d-md-none" />
                </b-nav-item>
                <b-nav-item role="presentation" class="font-weight-bold" :active="isActive('statistics')"
                  @click="setActive('statistics')">
                  <span class="d-none d-md-block">İstatistik</span>
                  <feather-icon icon="BarChartIcon" class="d-block d-md-none" />
                </b-nav-item>
                <b-nav-item role="presentation" class="font-weight-bold" :active="isActive('logs')"
                  @click="setActive('logs')">
                  <span class="d-none d-md-block">Log</span>
                  <feather-icon icon="MonitorIcon" class="d-block d-md-none" />
                </b-nav-item>
                <b-nav-item role="presentation" class="font-weight-bold" :active="isActive('projects')"
                  @click="setActive('projects')">
                  <span class="d-none d-md-block">Projeler</span>
                  <feather-icon icon="FileIcon" class="d-block d-md-none" />
                </b-nav-item>
                <b-nav-item v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-1
        variant="outline-primary" class="font-weight-bold" 
                  >
                  <span class="d-none d-md-block">Kayıtları İndir</span>
                  <feather-icon icon="FileIcon" class="d-block d-md-none" />
                  <b-modal
      id="modal-1"
      title="Ekran görüntülerini indir!"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text class="pb-2">
       
     Lütfen başlangıç ve bitiş tarihi seçini!<br>

    <span style="font-size: 10px;">(Talep edilen dosya kullanıcı mail adresinize otomatik olarak gönderilecektir!)</span> 
      </b-card-text>
         <!-- multiple dates -->
    <b-col md="6" class="mt-2 mb-2 " style="margin-left: 16%!important;">
      <b-form-group style="width: 300px;
    /* margin: auto; */
    ">
     
        <flat-pickr
          v-model="multiDate"
          class="form-control mt-2"
          placeholder="Tarih seçmek için tıkla!"
          :config="pickerConfig"
        />
      </b-form-group>
    </b-col>
    <div class="text-center mt-2">
       <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="secondary"
      @click="getScreenReport"
     
    >
      İndir
    </b-button>
    </div>
     
    </b-modal>

                </b-nav-item>
              </template>

              <!-- edit buttons -->
              <!-- <template #tabs-end>
                <b-button variant="primary" class="ml-auto">
                  <feather-icon icon="EditIcon" class="d-block d-md-none" />
                  <span class="font-weight-bold d-none d-md-block">Edit</span>
                </b-button>
              </template> -->
              <!-- edit buttons -->
            </b-tabs>
          </b-collapse>

          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/layouts/components/videoPlayer"
import { mapActions } from 'vuex';

import { BProgress } from 'bootstrap-vue'
import { mapGetters } from "vuex";
import { Api } from "@/helpers/Api/apiConfig";
import { BASE_URL } from "@/helpers/Url/BaseUrl";
import VuePerfectlooper from "vue-perfectlooper";
// let amazonCDN = 'https://aws-website-videonuclearpixelcom-tgl8t.s3.amazonaws.com/content/';
import { BCard,BModal,
  BAlert, BImg,VBModal, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BRow, BCol, BFormGroup, BPagination, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import store from '@/store/index'
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'; // Türkçe dil desteği
export default {
  data() {
    return {
      multiDate:null,
      pickerConfig: {
        mode: 'multiple',
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true, // 24 saat formatı
        locale: Turkish, // Türkçe dil desteği
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length > 2) {
            // En fazla iki tarih seçilebilir; önceki tarihleri kaldır
            selectedDates.splice(0, selectedDates.length - 2);
          }

          // Seçilen tarihleri kronolojik sıraya göre düzenle
          selectedDates.sort((a, b) => a - b);

          // Tarihleri güncelle
          instance.setDate(selectedDates);
        },
      },
///videoplaer new ///
getImages:[
        { id: 0, active:0,url: ['https://maminmagazinchik.ru/wp-content/uploads/1/8/5/185a2434f732af747241d50fd1dfa3b1.png', 'https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-01' },
        { id: 1, active:1,url: ['https://picsum.photos/200/300?random=2', 'https://picsum.photos/200/300?random=3'], date: '2022-01-02' },
        { id: 2,active:0, url: ['https://picsum.photos/200/300?random=3', 'https://picsum.photos/200/300?random=4'], date: '2022-01-03' },
        { id: 3,active:1,url: ['https://picsum.photos/200/300?random=4', 'https://picsum.photos/200/300?random=5'], date: '2022-01-04' },
      ],

//////

      baseUrl: BASE_URL,
      userId: null,
      ///video speed
      currentPage: 1,
      perPage: 1,
      rows: 3,
      speedCount: 1,
      defaultImg: 'https://vagsis.eralpsoftware.net/img/logo.e146a42c.png',
      //7
      ///DATEPİCKER ////
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      dateText: 'Tarih Seç',
      /////
      ///VİDEO PLAYER///
      autoplay: false,
      autoplayDelay: 3000,
      autoplayInterval: null,
      currentSlideIndex: 0,
      isFullscreen: false,
      value: 80,
      formattedDuration: null,
      activeTime: '0:00:00',

      ////////
      dataScreens: 0,
      tabIndex: 1,
      activeTab: "",
      isLoading: false,
      loop: {
        // "id": "crusaderarts_red_room_tribute-24",
        //             "poster": amazonCDN + "crusaderarts_red_room_tribute-24-preview.jpg",
        //             "src": amazonCDN + "crusaderarts_red_room_tribute-24-1920x1080/",
        //    "frames": 24,
        id: "test0",
        poster: require("@/assets/banner.jpg"),
        frames: 24, //1000,
        fps: 0.5,


        src: [],
        dates: []
      },
    };
  },


  components: {
    videoPlayer,
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    VuePerfectlooper,
    BProgress,
    VueSlider,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BPagination,
    BSpinner,
    store,
    BModal,
    BAlert,
    VBModal

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    headerData: {
      type: Object,
      default: () => { },
    },
    userData: {
      type: Object,
      default: () => { },
    },
  },
  watch: {
    "$route.params.user_id": {
      handler: function (value) {
       

        if(value){
        this.$store.commit('logScreen/setScreensLog', []);
      this.$store.commit('logScreen/setScreenDates', []);
      }
      },
      deep: true,
      immediate: true,
    },
    speedCount: function (newPage) {
      this.autoplayDelay = 3000
      newPage > 1 ? this.autoplayDelay = this.autoplayDelay / (newPage * 2) : ''
      this.setAutoplayDelay()
    },
    autoplay(val) {
      this.autoplay = val
      alert('autoplay',val)
    },
    autoplayDelay(val) {
      this.autoplayDelay = val
    },
    activeTab(val) {
      // alert(val)
      this.$emit("activeTab", val);
      if(this.activeTab != 'logs'){
        this.$store.commit('logScreen/setScreensLog', []);
      this.$store.commit('logScreen/setScreenDates', []);
      }
    },
   
    formattedDuration(val) {
      this.formattedDuration = val
    },
    currentSlideIndex(val) {
      this.currentSlideIndex = val



    },
  

  },
  computed: {
    ...mapGetters({ screensLog: "logScreen/screensLog", screenDates: "logScreen/screenDates" }),

  },

  methods: {
   async getScreenReport(){
   
      // String olarak verilen tarih aralığı
      const dateRange = this.multiDate

// String'i ayırarak ayrı tarih ve saatleri elde et
const dates = dateRange.split(', ');

// Tarih ve saati yerel saat diliminde ISO formatına dönüştürme fonksiyonu
function formatToLocalISO(dateTimeString) {
    const date = new Date(dateTimeString);
    
    // Tarih ve saati yerel saat diliminde ISO formatına dönüştür
    const tzOffset = date.getTimezoneOffset() * 60000; // Saat dilimi farkını milisaniye cinsinden hesapla
    const localISOTime = new Date(date.getTime() - tzOffset).toISOString().slice(0, -1); // Saat dilimi farkını çıkararak ISO formatı
    return localISOTime;
}

// Tarih ve saatleri ayrı değişkenlere ata ve yerel ISO formatına çevir
const startDate = formatToLocalISO(dates[0]);
const endDate = formatToLocalISO(dates[1]);
// Sonuçları konsolda göster
console.log("Start Date:", startDate);
console.log("End Date:", endDate);

      await Api('GET',`Log/SendZipUrl?departmentUserId=${this.userData.departmentUserId}&startDate=${startDate}&finishDate=${endDate}`)
          .then(response => {
              alert(response.data.data);
            

          })
          .catch(error => {
              console.log(error)
          })
    },
    ...mapActions('screens', ['updateScreensLog']),
  SelectedDateScreenFromVideoPlayer(val){
console.log('aaaa',val);
this.$emit('selectedDateFromVideoPlayer', val);
},
   async getDateScreen(val) {
      console.log('geldi', val);
      this.isLoading = true
         await Api('GET',`Log/GetScreens?departmentUserId=${this.userData.departmentUserId}&startDate=${val[0]}&finishDate=${val[1]}`)
          .then(response => {
              console.log('userScreenRange',response.data.data);
             // this.items = response.data.data.logInfos
             let rangeScreens = response.data.data.map((e,index) =>{
                return {
                  id:index,
active:e.whichScreenActive,
url:e.images.map((img) => BASE_URL+img),
date:new Date(e.createdDate).toLocaleString('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\./g, '/'),
tag:e.tag
                }
             })
             this.$store.commit('logScreen/setScreensLog', rangeScreens);

          })
          .catch(error => {
              console.log(error)
          })
          this.isLoading = false
    },
    speed() {
      if (this.speedCount === 5) {
        this.speedCount = 1;
      } else {
        this.speedCount++;
      }
    },
    pushList(data) {
      // alert('geldi')
      // let a = []
      // a.push(data)
      this.loop.src = [];

      setTimeout(() => {
        const id = this.dataScreens + 1;
        this.loop = {
          id: "test" + id,
          poster: require("@/assets/banner.jpg"),
          frames: 24, //1000,
          fps: 0.5,
          src: data,
        };
        setTimeout(() => {
          this.dataScreens++;
        }, 1000);
      }, 1000);
      // this.loop.src =[]
      // this.loop.src = data

      console.log(data);
      // alert(val)
    },

  
    isActive(tab) {
      return this.activeTab === tab || (this.activeTab === "" && tab === "profile");
    },
    setActive(tab) {
      this.activeTab = tab;

      // switch (this.activeTab) {
      //   case 'statistics':
      //     this.$router.push({ name: 'Home' });
      //     break;

      //   default:
      //     break;
      // }
    },
   
  
 
  
  


  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.modal-content{
  height: 12vh;
}
.myLoopHolder {
  height: 100% !important;
}

canvas {
  height: 60vh !important;
  left: 0px !important;
  right: 0px !important;
  // margin: auto;
  // width: 50% !important;
}

.perfect-looper {
  height: 30vh !important;
}

.aspectEnforcer {
  // padding-bottom: 15.25% !important;
  padding-bottom: 4.25% !important;
  height: 30vh;
}



.profile-header-nav {
  margin-top: 1vh !important;
}

.firstFocus {
  display: block;
  position: absolute;
  width: 100%;
  height: 50vh !important;
}

.profile-header .profile-img-container {
  position: absolute;
  bottom: -12rem !important;
  z-index: 2;
}

.profile-img-container {
  background: #28304687;
  border-radius: 9px;
  padding: 20px 40px 0px 15px;
}

.profile-img {
  border-radius: 50% !important;

}

.profile-header .profile-img-container {
  position: absolute;
  bottom: -10.6rem !important;
  z-index: 2;
}

// .perfectlooper-control{
//   top: 39vh !important;
// }



</style>

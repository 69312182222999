<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <!-- {{ userData }} -->
    <!-- fff{{ activeTab }} -->
    <profile-header v-if="userData" :header-data="profileData.header" :user-data="userData" @selectedDateFromVideoPlayer="getSelectedDateFromVideoPlayer($event)"
      @activeTab="handleActiveTab($event)" />
    <!-- profile info  -->
    <section id="profile-info" v-if="activeTab == 'profile'">

      <b-card v-if="!isLoading">

        <b-row>
          <!-- {{ userData }} -->
          <!-- User Info: Left col -->
          <!-- <b-col
    cols="21"
    xl="4"
    class="d-flex justify-content-between flex-column"
  >
    <div class="d-flex justify-content-start">
   
      <div class="d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0">
           Ad: {{ userData.fullName }}
          </h4>
          <span class="card-text">E-mail:{{ userData.email }}</span>
        </div>
     
      </div>
    </div>

   
    <div class="d-flex align-items-center mt-2">
      <div class="d-flex align-items-center mr-2" style="margin: auto !important;">
      
      </div>

    </div>
  </b-col> -->

          <!-- Right Col: Table -->
          <b-col cols="12" xl="5" class="user-info-right">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Pc</span>
                </th>
                <td class="pb-50">
                  Eralp-5
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Departman</span>
                </th>
                <td class="pb-50">
                  {{ userData.department }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Ekran</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.isActive ? 'Açık' : 'Kapalı' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Yetki</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.role }}
                </td>
              </tr>

              <tr>
                <th>
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Tel</span>
                </th>
                <td>
                  {{ userData.phone }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon icon="MailIcon" class="mr-75" />
                  <span class="font-weight-bold user-info-text">Email</span>
                </th>
                <td>
                  {{ userData.email }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="12" xl="3" class="d-flex justify-content-between flex-column">
            <div class="ml-1 text-center">
<!-- {{ goalOverviewRadialBar.series }} -->
              <vue-apex-charts type="radialBar" height="245" :options="goalOverviewRadialBar.chartOptions"
                :series="goalOverviewRadialBar.series" /> <br>


              <b>Verimlilik</b>
            </div>
          </b-col>
          <b-col cols="12" xl="4" class="d-flex justify-content-between flex-column">
            <div class="ml-1 text-left" v-if="userProjects.length > 0">
              <h6>Kayıtlar</h6>
              <app-timeline v-for="(user, index) in userProjects" :key="index">

                <!-- <app-timeline-item
      :title="`Proje ${index+1}: ${user.projectName} `"
      :subtitle="`Departman: ${user.departmentName}`"
      :time="`Başlangıç: ${user.userCreatedAtProject}`"
      :variant="`${user.isProjectActive?'success':'danger'}`"
      
    /> -->
                <app-timeline-item>
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>Proje {{ index + 1 + ': ' + user.projectName }} </h6>
                    <small class="text-muted">{{ user.userCreatedAtProject }}</small>
                  </div>

                  <p>
                    <span class="align-bottom"><b>Departman: </b> </span>
                    <span class="align-bottom"> <b> Durum: </b>{{ user.isProjectActive ? 'Aktif' : 'Pasif ' }} </span>

                  </p>

                </app-timeline-item>

                <!-- <app-timeline-item
      title="Last milestone remain"
      subtitle="You are just one step away from your goal"
      time="3 minutes ago"
      variant="info"
    />

    <app-timeline-item
      title="Your are running low on time"
      subtitle="Only 30 minutes left to finish milestone"
      time="21 minutes ago"
      variant="warning"
    />

    <app-timeline-item
      title="Client Meeting"
      subtitle="New event has been added to your schedule"
      time="36 minutes ago"
    />

    <app-timeline-item
      title="Product Design"
      subtitle="Product design added in workflow"
      time="1 hour ago"
      variant="secondary"
    /> -->
              </app-timeline>
            </div>

            <h4 v-else>Kayıtlı proje bulunamadı!</h4>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <!--/ profile info  -->

    <Statistics v-if="activeTab == 'statistics' && !isLoading" :chartData="statisticsChartData" :userData="userData"/>
    <Logs v-if="activeTab == 'logs' && !isLoading" :userData="userData" :logIfDates="logIfDates" />
    <Projects v-if="activeTab == 'projects' && !isLoading" :userProjectData="userProjectData" />



  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import videoPlayer from '@/layouts/components/videoPlayer'

import ProfileHeader from './ProfileHeader.vue'
import Statistics from './Statistics.vue'
import Logs from './Logs.vue'
import Projects from './Projects.vue'
// import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
// import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
// import ProfilePost from './ProfilePost.vue'
// import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
// import ProfileSuggestion from './ProfileSuggestion.vue'
// import ProfilePolls from './ProfilePolls.vue'

import profileBottom from './profileBottom.vue'
import { Api } from '@/helpers/Api/apiConfig'
import { BASE_URL } from '@/helpers/Url/BaseUrl'
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

/* eslint-disable global-require */
export default {
  components: {
    VueApexCharts,
    BCard, BButton, BRow, BCol, BAvatar,
    videoPlayer,

    ProfileHeader,
    Statistics,
    Logs,
    Projects,
    AppTimeline,
    AppTimelineItem,
    profileBottom,
  },
  data() {
    return {
      logIfDates:['',''],
      userProjectData: [],
      statisticsChartData: [],
      userProjects: [],
      selectedUserId: 1,
      activeTab: 'profile',
      poster: require("@/assets/banner.jpg"),
      isLoading: false,
      avatarText,
      userData: {
        avatar: '',
        fullName: '',
        role: '',
        email: '',
        id: '',
        department: '',
        isActive: null,
        phone: '',
        isAdmin: null,
        efficient: 0
      },
      profileData: {
        header: {
          avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
          username: 'Kitty Allanson',
          designation: 'UI/UX Designer',
          coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
        },
        userAbout: {
          about: 'Tart I love sugar plum I love oat cake. Sweet ⭐️ roll caramels I love jujubes. Topping cake wafer.',
          joined: 'November 15, 2015',
          lives: 'New York, USA',
          email: 'bucketful@fiendhead.org',
          website: 'www.pixinvent.com',
        },
        suggestedPages: [
          {
            avatar: require('@/assets/images/avatars/12-small.png'),
            username: 'Peter Reed',
            subtitle: 'Company',
            favorite: false,
          },
          {
            avatar: require('@/assets/images/avatars/1-small.png'),
            username: 'Harriett Adkins',
            subtitle: 'Company',
            favorite: false,
          },
          {
            avatar: require('@/assets/images/avatars/10-small.png'),
            username: 'Juan Weaver',
            subtitle: 'Company',
            favorite: false,
          },
          {
            avatar: require('@/assets/images/avatars/3-small.png'),
            username: 'Claudia Chandler',
            subtitle: 'Company',
            favorite: false,
          },
          {
            avatar: require('@/assets/images/avatars/5-small.png'),
            username: 'Earl Briggs',
            subtitle: 'Company',
            favorite: true,
          },
          {
            avatar: require('@/assets/images/avatars/6-small.png'),
            username: 'Jonathan Lyons',
            subtitle: 'Beauty Store',
            favorite: false,
          },
        ],
        twitterFeeds: [
          {
            imgUrl: require('@/assets/images/avatars/5-small.png'),
            title: 'Gertrude Stevens',
            id: 'tiana59 ',
            tags: '#design #fasion',
            desc: 'I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.',
            favorite: false,
          },
          {
            imgUrl: require('@/assets/images/avatars/12-small.png'),
            title: 'Lura Jones',
            id: 'tiana59 ',
            tags: '#vuejs #code #coffeez',
            desc: 'Halvah I love powder jelly I love cheesecake cotton candy. 😍',
            favorite: true,
          },
          {
            imgUrl: require('@/assets/images/avatars/1-small.png'),
            title: 'Norman Gross',
            id: 'tiana59 ',
            tags: '#sketch #uiux #figma',
            desc: 'Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.',
            favorite: false,
          },
        ],
        post: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-18.jpg'),
            username: 'Leeanna Alvord',
            postTime: '12 Dec 2018 at 1:16 AM',
            postText:
              'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@/assets/images/profile/post-media/2.jpg'),
            likes: 1240,
            youLiked: true,
            comments: 1240,
            share: 1240,
            likedUsers: [
              { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
            ],
            likedCount: 140,
            detailedComments: [
              {
                avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
                username: 'Kitty Allanson',
                comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                commentsLikes: 34,
                youLiked: false,
              },
              {
                avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
                username: 'Jackey Potter',
                comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                commentsLikes: 61,
                youLiked: true,
              },
            ],
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-22.jpg'),
            username: 'Rosa Walters',
            postTime: '11 Dec 2019 at 1:16 AM',
            postText:
              'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@/assets/images/profile/post-media/25.jpg'),
            likes: 1240,
            youLiked: true,
            comments: 1240,
            share: 1240,
            likedUsers: [
              { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
            ],
            likedCount: 271,
            detailedComments: [
              {
                avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
                username: 'Kitty Allanson',
                comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                commentsLikes: 34,
                youLiked: false,
              },
            ],
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
            username: 'Charles Watson',
            postTime: '12 Dec 2019 at 1:16 AM',
            postText:
              'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postVid: 'https://www.youtube.com/embed/6stlCkUDG_s',
            likes: 1240,
            youLiked: true,
            comments: 1240,
            share: 1240,
            likedUsers: [
              { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
              { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
            ],
            likedCount: 264,
            detailedComments: [
              {
                avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
                username: 'Kitty Allanson',
                comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                commentsLikes: 34,
                youLiked: false,
              },
            ],
          },
        ],
        latestPhotos: [
          { img: require('@/assets/images/profile/user-uploads/user-13.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-02.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-03.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-04.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-05.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-06.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-07.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-08.jpg') },
          { img: require('@/assets/images/profile/user-uploads/user-09.jpg') },
        ],
        suggestions: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
            name: 'Peter Reed',
            mutualFriend: '6 Mutual Friends',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
            name: 'Harriett Adkins',
            mutualFriend: '3 Mutual Friends',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
            name: 'Juan Weaver',
            mutualFriend: '1 Mutual Friends',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            name: 'Claudia Chandler',
            mutualFriend: '16 Mutual Friends',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
            name: 'Earl Briggs',
            mutualFriend: '4 Mutual Friends',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
            name: 'Jonathan Lyons',
            mutualFriend: '25 Mutual Friends',
          },
        ],
        polls: [
          {
            name: 'RDJ',
            result: '82%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-12.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Kelle Herrick' },
              { img: require('@/assets/images/portrait/small/avatar-s-10.jpg'), username: 'Len Bregantini' },
              { img: require('@/assets/images/portrait/small/avatar-s-11.jpg'), username: 'John Doe' },
            ],
          },
          {
            name: 'Chris Hemswort',
            result: '67%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
        ],
      },
      goalOverviewRadialBar: {
        series: [83],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#FFFFFF',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      }
    }
  },
  watch: {
    userData(value) {
      this.userData = value
      // this.goalOverviewRadialBar.series=value.efficient
    },
    "$route.params.user_id": {
      handler: function (value) {
        //  alert(value);

        this.selectedUserId = value
        console.log('selectedUserId', this.selectedUserId);
        this.getUserData(this.selectedUserId)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log('dfdfdfdfdf', this.selectedUserId)
    this.getUserData(this.selectedUserId)
  },
  methods: {

    getSelectedDateFromVideoPlayer(val){
console.log('gelgel',val)
this.logIfDates=val

    },
    dateFormat(date) {
      dayjs.locale('tr');
      return dayjs(date).format('DD MMMM YYYY HH:mm');
    },

    //   async getUserProjects (userId){
    //     this.isLoading = true
    //       await Api('GET','ProjectDepartmentUser?userId='+userId).then(res=>{


    //     this.userProjects = res.data.data.map(user => {
    //       return ({

    //         projectName:user.project.name,
    //         userCreatedAtProject:this.dateFormat(user.project.user.createdAt),

    //         departmentName:user.departmentUser.department.name,


    //         isProjectActive:user.project.isActive
    //       })
    //     })


    //     console.log('user projects',this.userProjects);



    // })
    // .catch(error => {
    //     console.error(error);
    //   });
    //   this.isLoading = false
    //     },
    async getUserData(userId) {
      this.isLoading = true
      //this.$http.get('/profile/data').then(res => { this.profileData = res.data })
      await Api('GET', 'DepartmentUser/GetAllDepartmentUserDetail' + '?userId=' + userId).then(res => {
        //alert(res.data.data[0].id )
        console.log('resDepartuser', res.data.data.items);
        const item = res.data.data.items.length > 0 ? res.data.data.items[0] : { projects: [] }
        const userData = res.data.data.items.map(user => {
          return ({

            avatar: require('@/assets/images/avatars/1.png'),//BASE_URL+user.user.image,
            fullName: user.user.name + ' ' + user.user.surname,
            role: user.user.generalRole !== 'User' ? 'Yönetici' : 'Çalışan',
            email: user.user.email,
            id: user.user.userId,
            department: user.department.name,
            departmentId: user.department.departmentId,
            isActive: user.user.isActive,
            phone: user.user.phoneNo,
            isAdmin: user.user.role,
            departmentUserId: user.departmentUserId,

            efficient: user.departmentUserDayBasedProductivity.efficientLast30Day.efficientPercentage
          })
        })
        
      this.userData = userData[0]
        this.goalOverviewRadialBar.series[0]=Number(parseFloat(userData[0].efficient))
        console.log('  this.goalOverviewRadialBar.series[0]',  this.goalOverviewRadialBar.series[0],Number(parseFloat(userData[0].efficient)));
        this.userProjects = res.data.data.items[0].projects.length > 0 ? res.data.data.items[0].projects.map(project => {
          return ({

            projectName: project.project.name,
            userCreatedAtProject: this.dateFormat(project.project.createdDate),

            //departmentName:user.departmentUser.department.name,


            isProjectActive: project.project.isActive//user.efficiencyInformation.efficient
          })
        }) : ''
        console.log('user', this.userData);

        //  this.getUserProjects(userId)

        this.statisticsChartData = res.data.data.items.map(data => {
          return (
            data.departmentUserMonthBasedProductivity
          )
        })



        // this.userProjectData = item.projects.map(project => {
        //   return project.project 
        // })
        this.userProjectData = item.projects.map(project => {
  return {...project.project,
    projectDepartmentUserWorkTime:project.projectDepartmentUserWorkTime,
   
   
     registerTotalDays:project.registerTotalDays,
  };
});

        console.log('userProjectData', this.userProjectData);
      })

        .catch(error => {
          console.error(error);
        });
      this.isLoading = false
    },
    handleActiveTab(val) {
      // Do something with the received data
      this.activeTab = val
      console.log('geldi', val);
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';

.user-info-right {
  background: #7367f02e;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px -1px 5px 0px #ffffffcc;
}

.user-info-text {
  color: #ffffff;
}
#user-profile .profile-header .profile-img-container {
  position: static;
  bottom: -2rem;
  z-index: 2;
}
.profile-header-nav {
  margin-top: 10vh;
}
</style>

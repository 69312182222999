<template>
  <div>
     <!-- {{userProjectData}} -->
    <b-table
 v-if="userProjectData"
      small
      :fields="fields"
      :items="userProjectData"
      responsive="sm"
      striped
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
       
      {{ data.index + 1 }} 
      </template>
      <template #cell(createdDate)="data">
        {{ new Date(data.item.createdDate).toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric' }) }}
      
      </template>
      <!-- A custom formatted column -->
      <template #cell(name)="data">
        {{ data.item.name }}
      
      </template>
      <template #cell(Department)="data">
        <!-- {{ data.item.user }} -->
        {{ data.item.department.name  }}
      </template>
      <template #cell(Role)="data">
       
        {{ data.item.projectOpenerUser.name }} {{ data.item.projectOpenerUser.surname }} -{{ data.item.projectOpenerUser.generalRole  }}
      </template>
      <template #cell(workingTime)="data">
      
       
    
        <p style="color: #7367F0;font-weight: bold;margin-top:15px">           {{ saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).saat+'s:'+saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).dakika+'dk:'+saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).saniye+'sn'}}
</p>
   
      </template>
      <!-- A custom formatted column -->
      <template #cell(Productivity)="data">
          <!-- data.item.efficiencyInformation.efficient -->
        <b-progress
       
          :value="data.item.projectDayBasedProductivity.efficientLast365Day.efficientPercentage "
          max="100"
          variant="data.value.variant"
          striped
         
        />
      
        {{ data.item.projectDayBasedProductivity.efficientLast365Day.efficientPercentage + '%' }}  <i size="xs" class="float-right"></i>
      </template>
    
      <template #cell(status)="data">
        <b-badge
        
          pill
          :variant="data.item.isActive ? 'success' : 'danger'"
        >
          {{ data.item.isActive !== '/Images/defaultUser.jpg'? 'Aktif' : 'Kapalı' }}
        </b-badge>
      </template>

      <!-- A virtual composite column -->
      <template #cell(price)="data">
        {{ '$'+data.value }}
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BProgress, BBadge } from 'bootstrap-vue'
import { Api } from '@/helpers/Api/apiConfig'
export default {
  components: {
    BTable,
    BProgress,
    BBadge,
  },
  data() {
    return {
      selectedDepartment: 28,
      fields: [
        // A virtual column that doesn't exist in items
        
        { key: 'index', label: 'Proje Sayısı' },
        // A column that needs custom formatting
        { key: 'createdDate', label: 'Proje Oluşturulma Tarihi' },

        { key: 'name', label: 'Proje Adı' },
        { key: 'Department', label: 'Departman' },
        { key: 'Role', label: 'Projeyi Oluşturan '},
        { key: 'workingTime', label: 'Çalışma süresi' },


        
        // A regular column
        { key: 'Productivity', label: 'Verimlilik' },
        // A regular column
       { key: 'status', label: 'Durum' },
        // A virtual column made up from two fields
      //   { key: 'price', label: 'Aktif Ekran' },
      ],
      items: [
        {
          name: { first: 'Fitbit', last: 'Activity Tracker' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 50, variant: 'success' },
          price: 99.99,
        },
        {
          name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
          Category: 'Fitness',
          order_status: { status: 'Pending', variant: 'primary' },
          Popularity: { value: 36, variant: 'primary' },
          price: 89.85,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 76, variant: 'success' },
          price: 65.25,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Wristband' },
          Category: 'Fitness',
          order_status: { status: 'On Hold', variant: 'warning' },
          Popularity: { value: 55, variant: 'warning' },
          price: 75.55,
        },
        {
          name: { first: 'Fitbit', last: 'Clip for Zip Wireless Activity Trackers' },
          Category: 'Fitness',
          order_status: { status: 'Canceled', variant: 'danger' },
          Popularity: { value: 75, variant: 'danger' },
          price: 105.55,
        },
      ],
    }
  },
  
  props:{
    userProjectData:{
      type: Object,
      required: true
    }
  },
  watch: {
    userProjectData(val) {
      this.items = val
    }
  },
  mounted() {
    this.getDepartmentUsers()
  },
  methods: {
    saniyeyiGuneCevir(saniye) {
  const gun = Math.floor(saniye / 86400); // 86400 saniye bir güne eşittir.
  saniye %= 86400; // Kalan saniyeyi alıyoruz.
  const saat = Math.floor(saniye / 3600); // 3600 saniye bir saate eşittir.
  saniye %= 3600; // Kalan saniyeyi alıyoruz.
  const dakika = Math.floor(saniye / 60); // 60 saniye bir dakikaya eşittir.
  saniye %= 60; // Kalan saniyeyi alıyoruz.

  return {
    gun,
    saat,
    dakika,
    saniye,
  }
},
     async getDepartmentUsers(){
     
              this.items = userProjectData
        
      }
  }
}
</script>
<template>
  <div class="row ">
    <!-- ss{{ isLoading }} -->
<!-- gelen{{ chartData }} -->
<div class="col-12 d-flex justify-content-center mb-1 text-center" variant="primary" v-if="isLoading">
  <strong class="text-center">Yükleniyor...</strong>
  <b-spinner class="m-auto" variant="primary" />
</div>

    <div class="col-lg-3 col-md-12 col-sm-12 sol-xs-13 mt-2" v-if="!isLoading">

      <div class="card programList p-2" >
        <h5 class="mb-2 text-center">En Çok Kullanılan Uygulamalar</h5>
        <app-timeline v-if="items.length > 0">
          <app-timeline-item v-for="item in items" :key="item"
            :title="item.productiveLevel === 0 ? 'Verimsiz' : item.productiveLevel === 1 ? 'Verimli' : 'Belirsiz'"
            :subtitle="item.program.tag" :time="` ${formatTime(item.sumTime)}`"
            :variant="item.productiveLevel === 0 ? 'danger' : item.productiveLevel === 1 ? 'success' : 'warning'">


          </app-timeline-item>
        </app-timeline>
        <p v-else class="text-center"> Kayıtlı Program Yok </p>
      </div>
    </div>
    <!-- ss{{ isLoading}}{{ series[0].data }} -->
    <div class="col-lg-9 col-md-12 col-sm-12 sol-xs-12 mt-2" v-if="!isLoading">
      <div class="card p-2 h-100">
        <div id="chart" >
          <VueApexCharts v-if="chartData[0].length>0 && !isLoading " type="line"
            height="350" :options="chartOptions" :series="series">

          </VueApexCharts>
          <p v-else class="text-center"> Kayıtlı Data Yok</p>


        </div>

      </div>


    </div>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { BTable, BProgress, BBadge,BSpinner } from "bootstrap-vue";
import { Api } from "@/helpers/Api/apiConfig";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    VueApexCharts,
    BSpinner
  },
  data() {
    return {
      isLoading: false,
      userId: null,
      //chart

      series: [
        {
          name: "Verimlilik Süresi",
          type: "column",
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        },
        {
          name: "Verimlilik Süresi",
          type: "line",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
          style: {
            colors: ["#FFFF"],
          }
        }
      ],

      chartOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Verimlilik Grafiği",
          style: {
            color: "#FFF",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        colors: ["#7366F0", "#29C76F"],

        xaxis: {
          type: "text",
        },

        yaxis: [
          {
            title: {
              text: "Verimlilik Süresi",
            },
          },
          {
            opposite: true,
            title: {
              text: "Verimlilik Süresi",
            },
          },
        ],
      },

      //chart end
      selectedDepartment: 28,
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Adı" },
        { key: "Department", label: "Departman" },

        // A regular column
        { key: "Productivity", label: "Verimlilik" },
        // A regular column
        { key: "status", label: "Durum" },
        // A virtual column made up from two fields
        //   { key: 'price', label: 'Aktif Ekran' },
      ],
      items: [],
    };
  },
  computed:{
   
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },

    userData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "$route.params.user_id": {
      handler: function (value) {
        //  alert(value);

        this.userId = value
        console.log('userId statistics', this.userId);
        this.getDepartmentUsers()
      },
      deep: true,
      immediate: true,
    },
    series(val){
      this.series=val
    }
  },
  mounted() {
    this.getDepartmentUsers();
  },
  created(){
    this.PushchartData()

  },
  methods: {
    PushchartData(){
      console.log('chart data', this.chartData);
      this.series[0].data=this.chartData[0].map(data=>{return(
data.efficientPercentage

      )})
      this.series[1].data=this.chartData[0].map(data=>{return(
data.efficientPercentage

      )})
      console.log('series', this.series[0].data);
    },
    async getDepartmentUsers() {
      console.log('istatistik',this.userData);
      this.isLoading = true
      await Api("GET", `Log/Statistics?userId=${this.userId}&departmentId=${this.userData.departmentId}`)//&startDate=2022-11-12&finishDate=2023-12-12
        .then((response) => {
          console.log("userençok", response.data.data);
          this.items = response.data.data.items
        })
        .catch((error) => {
          console.log(error);
        });
      this.isLoading = false
     // this.getEfficientLogs()
    },
    async getEfficientLogs() {
      // alert('eree')
      // this.isLoading = true
      // await Api("GET", `Log/GetEfficientLogUserMonths?userId=${this.userId}`)
      //   .then((response) => {
      //     console.log("verimlilik user chart datası", response.data.data);

      //     this.series[0].data = Object.values(response.data.data.monthlyEfficiency).map((month) => month.efficientPercentage);
      //     this.series[1].data = Object.values(response.data.data.monthlyEfficiency).map((month) => month.efficientPercentage);

      //     console.log("verimlilik  chart datası", this.series[0].data);
      //     console.log("verimlilik  chart datası2", this.series[1].data);

      //     // this.series[0].data = null


      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // this.isLoading = false
    },
    formatTime(time) {
      if (time > 0) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        let formattedTime = "";
        if (hours > 0) {
          formattedTime += hours + " sa ";
        }
        if (minutes > 0) {
          formattedTime += minutes + " dk ";
        }
        if (seconds > 0) {
          formattedTime += seconds + " sn ";
        }

        return formattedTime.trim();
      } else {
        return "";
      }
    },
  }
}
</script>
<style>
span.apexcharts-legend-text {
  color: white !important;
}

tspan {
  fill: white !important;
}

.programList {
  overflow-y: scroll;
  height: 62vh !important;
  margin-bottom: 0rem !important;
}
</style>

<template>
  <div>
    <!-- userdata {{ userData.departmentUserId }} -->
    <div class="d-flex justify-content-center mb-1" variant="primary" v-if="isLoading">
      <strong>Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="primary" />
    </div>

    <b-row v-if="!isLoading">
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Liste adeti</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <!-- <b-form-group
      label="Sırala"
      label-cols-sm="3"
      label-align-sm="right"
      label-size="sm"
      label-for="sortBySelect"
      class="mb-0"
    >
      <b-input-group size="sm">
        <b-form-select
          id="sortBySelect"
          v-model="sortBy"
          :options="sortOptions"
          class="w-75"
        >
          <template v-slot:first>
            <option value="">
              -- none --
            </option>
          </template>
</b-form-select>
<b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
  <option :value="false">
    Asc
  </option>
  <option :value="true">
    Desc
  </option>
</b-form-select>
</b-input-group>
</b-form-group> -->
      </b-col>
      <b-col md="6" class="my-1">
        <!-- <b-form-group
      label="Ara"
      label-cols-sm="3"
      label-align-sm="right"
      label-size="sm"
      label-for="filterInput"
      class="mb-0"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Ad ile arama yapın..."
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Temizle
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group> -->
      </b-col>
      <b-col cols="12">
        <b-table v-if="!isLoading" responsive :per-page="perPage" :current-page="1" :items="items" :fields="fields"
          striped>
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}


          </template>
          <template #cell(createdAt)="data">
            {{ formatliTarih(new Date(data.item.createdDateFirstLogThisProgram)) }}
          </template>
          <!-- <template #cell(lastCreatedAt)="data">
        {{    formatliTarih(new Date(data.item.lastCreatedAt))}}
      </template> -->
          <template #cell(productiveLevel)="data">
            <b-badge pill
              :variant="data.item.departmentProgram.productiveLevel == 1 ? 'success' : data.item.departmentProgram.productiveLevel == 0 ? 'danger' : 'warning'">
              {{ data.item.departmentProgram.productiveLevel == 1 ? 'verimli' : data.item.departmentProgram.productiveLevel ==
      0 ? 'verimsiz' : 'bilinmeyen' }}
              <div>
                <!-- <p>{{ formattedDuration }}</p> -->
              </div>
            </b-badge>
          </template>
          <!-- A custom formatted column -->
          <template #cell(name)="data">
            <!-- {{ data.item.user }} -->
            <span v-if="data.item.departmentProgram.program">

              {{ data.item.departmentProgram.program.tag || 'Kayıtlı Program Yok'}}
            </span>
          </template>
          <template #cell(Department)="data">
            <!-- {{ data.item.user }} -->
           <span v-if="data.item.departmentProgram.department">
            {{ data.item.departmentProgram.department.name ? data.item.departmentProgram.department.name : 'Kayıtlı Departman Yok' }}
           </span>
          </template>
          <!-- <template #cell(Project)="data">
        
        {{ data.item.project?data.item.project.name :'Kayıtlı Proje Yok' }}
      </template> -->
          <!-- A custom formatted column -->
          <!-- <template #cell(Productivity)="data">
        <b-progress
       
          :value="80"
          max="100"
          variant="data.value.variant"
          striped
        />
        {{ data.item.efficiencyInformation.efficient + '%' }}
      </template> -->

          <template #cell(time)="data">
            <b-badge pill
              :variant="data.item.departmentProgram.productiveLevel == 1 ? 'success' : data.item.departmentProgram.productiveLevel == 0 ? 'danger' : 'warning'">
              {{ formattedDuration(data.item.timeSpentThisProgram) }}
              <div>

              </div>
            </b-badge>
          </template>

          <!-- A virtual composite column -->
          <template #cell(watch)="data">
            <!-- {{ '$'+data.item.productiveLevel }}  -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon">
              <feather-icon icon="MonitorIcon" @click="getPartofScreen(data.item)" />
            </b-button>
          </template>

          <!-- Optional default data cell scoped slot -->
          <!-- <template #cell()="data">
        {{ data.value }}
      </template> -->
        </b-table>
      </b-col>
      <b-col cols="12">
        <!-- {{ currentPage }} -->
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
    <!-- <h3 v-else class="text-center">Kayıtlı Data yok</h3> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { BASE_URL } from '@/helpers/Url/BaseUrl'
import Ripple from "vue-ripple-directive";

import moment from 'moment';
import { BTable, BProgress, BBadge, BButton, BSpinner, BCol, BRow, BPagination, BFormSelect, BFormGroup } from 'bootstrap-vue'
import { Api } from '@/helpers/Api/apiConfig'
export default {
  components: {
    BFormGroup,
    BFormSelect,
    BTable,
    BProgress,
    BBadge,
    BButton,
    BSpinner,
    BCol,
    BRow,
    BPagination
  },
  data() {
    return {
      startDate:'',
finishDate:'',
      ////table///
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [3, 5, 10],


      //////table end////

      tarih: null,
      isLoading: false,
      // durationInSeconds: 7180,
      // formattedDuration: '',
      selectedDepartment: 28,
      userId: null,
      fields: [
        // A virtual column that doesn't exist in items
        // 'id',
        { key: 'productiveLevel', label: 'Verimlilik' },
        { key: 'createdAt', label: 'Baslama Tarihi' },
        // { key: 'lastCreatedAt', label: 'Biti Tarihi' },
        { key: 'name', label: 'Adı' },
        { key: 'Department', label: 'Departman' },
        // { key: 'Project', label: 'Proje' },



        // A regular column

        // A regular column
        { key: 'time', label: 'Süre (s:dk:sn)' },
        { key: 'watch', label: 'Ekran' },


        // A virtual column made up from two fields
        //   { key: 'price', label: 'Aktif Ekran' },
      ],
      items: [
        {
          name: { first: 'Fitbit', last: 'Activity Tracker' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 50, variant: 'success' },
          price: 99.99,
        },
        {
          name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
          Category: 'Fitness',
          order_status: { status: 'Pending', variant: 'primary' },
          Popularity: { value: 36, variant: 'primary' },
          price: 89.85,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 76, variant: 'success' },
          price: 65.25,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Wristband' },
          Category: 'Fitness',
          order_status: { status: 'On Hold', variant: 'warning' },
          Popularity: { value: 55, variant: 'warning' },
          price: 75.55,
        },
        {
          name: { first: 'Fitbit', last: 'Clip for Zip Wireless Activity Trackers' },
          Category: 'Fitness',
          order_status: { status: 'Canceled', variant: 'danger' },
          Popularity: { value: 75, variant: 'danger' },
          price: 105.55,
        },
      ],
    }
  },
  directives: {
    Ripple,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
    logIfDates:{
      type:Array,
      required:true
    }

  },

  watch: {
    logIfDates(val){
      this.startDate=val[0]
      this.finishDate=val[1]
      console.log('logg da deteler geldi',val);
      this.getDepartmentUsers(this.currentPage)
    },
    // perpage(val){
    //   this.getDepartmentUsers(val,this.currentPage)
    // },
    items(val) {
      this.items = val
    },
    currentPage(val) {
      this.getDepartmentUsers(val)
    },
    "$route.params.user_id": {
      handler: function (value) {
        //  alert(value);

        this.userId = this.userData
        console.log('userId', this.userId);
        value != null ? this.getDepartmentUsers(1, 10) : ''
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {

    this.getDepartmentUsers(this.currentPage)//this.selectedDepartment
    // const duration = moment.duration(this.durationInSeconds, 'seconds');
    // this.formattedDuration = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    this.tarih = new Date(this.tarihDizesi);
  },
  methods: {
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length
    //   this.currentPage = 1
    // },
    formattedDuration(time) {
      const duration = moment.duration(time, 'seconds');
      return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    },
    formatliTarih(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'Europe/Istanbul'
      };
      return date.toLocaleString('tr-TR', options)
    },
    async getDepartmentUsers(page) { //departmentId
      this.isLoading = true
      await Api('GET', `Log/GetAllAccordingToPrograms?departmentUserId=${this.userData.departmentUserId}&page=${page}&limit=${this.perPage}&sort=desc&startDate=${this.startDate}&finishDate=${this.finishDate}`)
        .then(response => {
          console.log('userlogs', response.data.data.logInfos);
          this.items = response.data.data.logInfos
          this.totalRows = response.data.data.count
        })
        .catch(error => {
          console.log(error)
        })
      this.isLoading = false
    },
    // async testGet(val){

    // },
    ...mapActions('screens', ['updateScreensLog']),
    getPartofScreen(logList) {
      console.log('logList', logList);
      var screensLog = null
      //     await Api('GET',`Log/?departmentUserId=${this.userData.departmentUserId}&page=1&limit=100&startDate=${createdDate}&sort=asc&page=1`) // (active time / 10sn) olarak hesaplanıp limit olarak gönderilmeli
      //           .then(response => {
      //               console.log('logData',response.data.data);
      //               //this.items = response.data.data
      //                let screensLog= response.data.data.items.map((log,index) =>  {return{id:index,url:BASE_URL + log.image,date:
      //           JSON.stringify(log.createdAt.split("T").map((part) => {
      //   if (part.includes(":")) {
      //     // Saat kısmını biçimlendir
      //     return part.split(".")[0];
      //   } else {
      //     // Tarih kısmını biçimlendir
      //     const [year, month, day] = part.split("-");
      //     return `${day}/${month}/${year}`;
      //   }
      // })),
      // exeName:log.exeName,
      // productiveLevel:log.productiveLevel,
      // name:log.name




      //         }});


      // let dates = screensLog.map((e)=>{return e.date});
      //       screensLog = logList.imageInfos.flatMap((e) => {
      //   return e.images.map((img) => BASE_URL+img);
      // });

      screensLog = logList.imageInfos.flatMap((e, index) => {  ///screen log store daki verileri getImages yapısına göre revize edicez!
        return {
          id: index,
          active: e.whichScreenActive,
          url: e.images.map((img) => BASE_URL + img),
          date: new Date(e.createdTime).toLocaleString('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\./g, '/')



        }
      });

      let dates = logList.imageInfos.flatMap((e) => {
        return e.createdTime;
      });
      console.log('screensLog11', screensLog);
      console.log('dates', dates);


      //               //this.$emit('screensLog',screensLog)
      //               //  this.updateScreensLog(screensLog);
      this.$store.commit('logScreen/setScreensLog', screensLog);
      this.$store.commit('logScreen/setScreenDates', dates);
      //  this.$store.commit('logScreen/setScreenDates', dates);


      window.scrollTo(0, 0);
      //           })
      //           .catch(error => {
      //               console.log(error)
      //           })
    }
  }

}
</script>